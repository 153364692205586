import React from 'react';
import "../scss/main.scss"
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import StartWork from '../components/forms/StartWork';
import Testimonials from '../components/Organism/testimonials/testimonials'

function AboutUs(props) {
    const form = <StartWork/>;
    return (
        <>
            <Helmet>
                <title>Инфонрамция о студии разработки сайтов Androsov Digital</title>
                <meta name="description" content="Разработка сайтов под ключ с высокой конверсией, соблюдаем сроки и используем новейшие технолигии" />
            </Helmet>
            <Header/>
            <Main>
                <PageHeader 
                    h1={"Немного о компании"} 
                    сards={[
                                {
                                    name:"Работаем с 2016 года",
                                    desk:"Путь к студии был долгим. Мы прошли через фриланс. Работали в аутсорсинговых компаниях. Создавали разные продукты."
                                },
                                {
                                    name:"1000+ проектов",
                                    desk:"Разрабатывали анимационные видео, писали статьи, настраивали контекстную рекламу. И конечно же разрабатывали сайты"
                                },
                                {
                                    name:"Базируемся в Челябинске",
                                    desk:"Работаем со всей планетой, находясь в маленьком, но гордом городе России"
                                },
                            ]}

                />

            </Main>
            <Footer/>
        </>
    );
}

export default AboutUs;